.Layout .Footer {
    flex: 0 1 40px;
}

.Footer {
    background-color: #FFFFFF;
    color: #000000;
    font-size: calc(10px + 1vmin);
    
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.social-media {
    flex: 1;
    text-align: center;
}

.copyright {
    flex: 4;
    text-align: center;
    margin: 0;
}

.red {
    flex: 1;
    height:100%;
    background-color: #F05A48;
}

.yellow {
    flex: 1;
    height:100%;
    background-color: #FDE748;
}

.blue {
    flex: 1;
    height:100%;
    background-color: #5998D0;
}