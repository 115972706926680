.Layout .App {
  flex: 1 1 0;
}

.App {
  text-align: center;
}

.App-logo {
  width: 70vmin;
  cursor: pointer;
}

.App-logo.twirl {
  animation: twirl 1s;
}

.App-logo.alert {
  animation: alert 0.5s;
}

@keyframes twirl {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes alert {
  50% {
    transform: scale(1.5);
  }
  100%, 0% {
    transform: scale(1);
  }
}


.App-header {
  background-color: #F0F0FF;
  color: #202030;
  
  height: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}
